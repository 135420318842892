<template>
  <v-container class="header">
    <div class="text-right">
      <v-btn
        outlined
        color="accent"
        class="button pa-5 mr-3"
        @click="toggleActiveList"
      >
        {{ $t(`components.lists.buttons.active.${active}`) }}
      </v-btn>

      <v-btn
        color="accent"
        class="button pa-5 ml-3"
        @click="openDialog"
      >
        {{ $t('components.lists.buttons.register_adjustment') }}
      </v-btn>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'AdjustmentsHeader',
  data() {
    return {
      active: true,
    };
  },
  methods: {
    toggleActiveList() {
      this.active = !this.active;
      this.fetchAdjustments();
    },
    fetchAdjustments() {
      this.$emit('fetchAdjustments', this.active);
    },
    openDialog() {
      this.$emit('openModal');
    },
  },
};
</script>

<style lang="scss" src="@/assets/scss/components/headers/drivers.scss" scoped />
