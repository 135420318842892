<template>
  <v-row
    no-gutters
    justify="center"
  >
    <v-dialog
      v-model="dialogOpen"
      max-width="730px"
      :change="closeModal()"
    >
      <v-card>
        <v-card-title class="dialog-title">
          <span>{{ dialogInfos.title }}</span>
        </v-card-title>
        <v-card-text
          class="dialog-subtitle"
        >
          {{ dialogInfos.subtitle }}
        </v-card-text>
        <v-card-text
          v-if="deactivate"
          class="dialog-details"
        >
          <adjustment-details
            :adjustment="adjustment"
            :password-clean="dialogOpen"
            @toggleActivationAdjustment="toggleActivationAdjustment"
          />
        </v-card-text>
        <v-card-text
          v-else
          class="dialog-form"
        >
          <adjustment-form
            :edit="edit"
            :adjustment="adjustment"
            :kinds="kinds"
            @createAdjustment="createAdjustment"
            @updateAdjustment="updateAdjustment"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import AdjustmentDetails from '@/components/details/AdjustmentDetails.vue';
import AdjustmentForm from '@/components/forms/register/AdjustmentForm.vue';

export default {
  name: 'AdjustmentsDialog',
  components: {
    AdjustmentForm,
    AdjustmentDetails,
  },
  model: {
    prop: 'dialog',
    event: 'change',
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    deactivate: {
      type: Boolean,
      default: false,
    },
    adjustment: {
      type: Object,
      default: () => {},
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    resetForm: {
      type: Boolean,
      default: false,
    },
    kinds: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialogOpen: false,
    };
  },
  computed: {
    shouldCleanForm() {
      return this.resetForm;
    },

    dialogInfos() {
      if (this.deactivate) {
        return {
          title: this.$t(`components.lists.adjustments.deactivate.title.active.${this.adjustment.active}`),
          subtitle: this.$t(`components.lists.adjustments.deactivate.subtitle.active.${this.adjustment.active}`),
        };
      }

      if (this.edit) {
        return {
          title: this.$t('components.lists.adjustments.edit.title'),
          subtitle: this.$t('components.lists.adjustments.edit.subtitle'),
        };
      }

      return {
        title: this.$t('components.lists.adjustments.register.title'),
        subtitle: this.$t('components.lists.adjustments.register.subtitle'),
      };
    },
  },
  watch: {
    dialog() {
      this.dialogOpen = this.dialog;
    },
  },
  methods: {
    createAdjustment(payload) {
      this.$emit('createAdjustment', payload);
    },

    updateAdjustment(payload) {
      this.$emit('updateAdjustment', payload);
    },

    toggleActivationAdjustment(managerPassword, adjustmentId) {
      this.$emit('toggleActivationAdjustment', { managerPassword, adjustmentId });
    },

    closeModal() {
      this.$emit('change', this.dialogOpen);
    },
  },
};
</script>

<style lang="scss" src="@/assets/scss/components/dialogs/drivers.scss" scoped />
