<template>
  <v-container class="pb-0">
    <v-row>
      <v-col
        cols="12"
        sm="6"
        class="gutter-right"
      >
        <v-text-field
          v-model="name"
          outlined
          dense
          required
          :label="$t('components.lists.holiday.name')"
          color="accent"
          height="42"
          class="dialog-input pb-4"
        />
        <v-menu
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-combobox
              v-model="formatedDate"
              dense
              outlined
              readonly
              persistent-hint
              :label="$t('components.lists.holiday.date')"
              height="42"
              color="accent"
              class="dialog-input pb-4"
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="date"
            scrollable
            color="accent"
            header-color="accent"
            locale="pt-br"
          />
        </v-menu>
        <v-select
          v-model="recurrence"
          outlined
          dense
          :items="recurrenceOptions"
          item-text="name"
          item-value="value"
          :label="$t('components.lists.holiday.recurrence')"
          color="accent"
          height="42"
          class="dialog-input pb-4"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        class="gutter-left"
      >
        <v-select
          v-model="businessUnitId"
          outlined
          dense
          :items="businessUnits"
          item-text="name"
          item-value="id"
          :label="$t('components.lists.holiday.unit')"
          color="accent"
          height="42"
          class="dialog-input pb-4"
        />
        <v-select
          v-model="branchId"
          outlined
          dense
          :items="formattedBranches"
          item-text="name"
          item-value="id"
          :label="$t('components.lists.holiday.branch')"
          color="accent"
          height="42"
          class="dialog-input pb-4"
        />
      </v-col>
    </v-row>

    <v-btn
      class="button"
      color="accent"
      @click="submitForm"
    >
      {{ buttonText }}
    </v-btn>
  </v-container>
</template>

<script>
import formats from '@/mixins/formats';

export default {
  name: 'DriverForm',
  mixins: [formats],
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    holiday: {
      type: Object,
      default: () => {},
    },
    businessUnits: {
      type: Array,
      default: () => [],
    },
    branches: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      name: '',
      recurrence: true,
      date: '',
      businessUnitId: null,
      branchId: null,
      formatedDate: '',
      recurrenceOptions: [
        {
          name: this.$t('components.lists.holiday.isRecurrent'),
          value: true,
        },
        {
          name: this.$t('components.lists.holiday.notRecurrent'),
          value: false,
        },
      ],
      formattedBranches: [],
    };
  },
  computed: {
    buttonText() {
      if (this.edit) {
        return this.$t('components.lists.holiday.edit.title');
      }

      return this.$t('components.lists.holiday.register.title');
    },
    computedDate() {
      return this.date;
    },
  },
  watch: {
    branches(value) {
      this.applyFormattedBranches(value);
    },
    computedDate() {
      this.formatedDate = this.formatDateISO(this.date);
    },
    holiday(value) {
      if (value) {
        this.name = value.name;
        this.recurrence = value.recurrence;
        this.date = value.date;
        this.businessUnitId = value.business_unit_id;
        this.branchId = value.branch_id;
      }
    },
    businessUnitId(value) {
      if (value) {
        this.$emit('branchesByBusinessUnit', { business_unit_id: value, by_active: true });
      }
    },
  },
  mounted() {
    this.applyFormattedBranches(this.branches);
    if (this.holiday) {
      this.name = this.holiday.name;
      this.recurrence = this.holiday.recurrence;
      this.date = this.holiday.date;
      this.branchId = this.holiday.branch_id;
      this.businessUnitId = this.holiday.business_unit_id;
    }
  },
  methods: {
    text(label) {
      return this.$t(`components.lists.holiday.${label}`);
    },
    applyFormattedBranches(branches) {
      this.formattedBranches = [];
      this.formattedBranches = [{ name: `${this.text('none')}`, id: '' }];
      this.formattedBranches.push(...branches);
    },
    submitForm() {
      const payload = this.assertPayload();

      this.$emit('submited', payload);
    },
    assertPayload() {
      const payload = {
        id: this.holiday.id,
        name: this.name,
        recurrence: this.recurrence,
        date: this.date,
        branch_id: this.branchId,
      };

      return payload;
    },
  },
};
</script>

<style lang="scss" src="@/assets/scss/components/dialogs/drivers.scss" scoped />
