<template>
  <div class="fill-height">
    <v-simple-table
      v-if="holidaysPresent"
      class="jornada-list"
    >
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-center">
              {{ $t('components.lists.holiday.id') }}
            </th>
            <th class="text-center">
              {{ $t('components.lists.holiday.name') }}
            </th>
            <th class="text-center">
              {{ $t('components.lists.holiday.date') }}
            </th>
            <th class="text-center">
              {{ $t('components.lists.holiday.recurrence') }}
            </th>
            <th class="text-center">
              {{ $t('components.lists.holiday.branch') }}
            </th>
            <th class="text-center" />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="holiday in holidays"
            :key="holiday.id"
            class="list-row"
          >
            <td class="text-center">
              {{ holiday.id }}
            </td>
            <td class="text-center">
              {{ holiday.name }}
            </td>
            <td class="text-center">
              {{ formatedDate(holiday.date) }}
            </td>
            <td class="text-center">
              {{ isRecurrent(holiday.recurrence) }}
            </td>
            <td class="text-center">
              {{ branchName(holiday.branch_name) }}
            </td>
            <td
              class="text-center buttons"
              width="100px"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="icon mr-3"
                    color="accent"
                    v-bind="attrs"
                    v-on="on"
                    @click="deleteHoliday(holiday)"
                  >
                    mdi-close-circle
                  </v-icon>
                </template>
                <span>{{ $t('components.lists.holiday.tooltips.delete') }}</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="icon ml-2"
                    color="accent"
                    v-bind="attrs"
                    v-on="on"
                    @click="updateHoliday(holiday)"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>{{ $t('components.lists.holiday.tooltips.edit') }}</span>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <placeholder
      v-else
      :message="$t('components.lists.holiday.empty')"
    />
  </div>
</template>

<script>
import Placeholder from '@/components/lists/Placeholder.vue';
import formats from '@/mixins/formats';

export default {
  name: 'HolidayList',
  components: {
    Placeholder,
  },
  mixins: [formats],
  props: {
    holidays: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    holidaysPresent() {
      return this.holidays.length !== 0;
    },
  },
  methods: {
    updateHoliday(holiday) {
      this.$emit('editHoliday', holiday);
    },
    deleteHoliday(holiday) {
      this.$emit('destroyHoliday', holiday);
    },
    formatedDate(date) {
      return this.formatDateISO(date, 'dd/MM/yy');
    },
    isRecurrent(value) {
      return value ? this.$t('components.lists.holiday.recurrent.yes') : this.$t('components.lists.holiday.recurrent.no');
    },
    branchName(name) {
      if (name) {
        return name;
      }
      return '-';
    },
  },
};
</script>

<style lang="scss">
.fill-height {
  min-height: 256px;
}

.placeholder {
  margin-top: 120px;

  .placeholder-icon {
    font-size: 100px;
  }

  .text {
    margin: auto;
    font-size: 32px;
    width: 330px;
    color: $color_placeholder_text;
  }
}
</style>
