<template>
  <v-row
    no-gutters
    justify="center"
  >
    <v-dialog
      v-model="dialogOpen"
      max-width="730px"
      :change="closeModal()"
    >
      <v-card>
        <v-card-title class="dialog-title">
          <span>{{ dialogInfos.title }}</span>
        </v-card-title>
        <v-card-text
          class="dialog-subtitle"
        >
          {{ dialogInfos.subtitle }}
        </v-card-text>
        <v-card-text
          v-if="deleteHoliday"
          class="dialog-details"
        >
          <holiday-details
            :holiday="holiday"
            :password-clean="dialogOpen"
            @deleteHoliday="doDeleteHoliday"
          />
        </v-card-text>
        <v-card-text
          v-else
          class="dialog-form"
        >
          <holiday-form
            :edit="edit"
            :holiday="holiday"
            :business-units="businessUnits"
            :branches="branches"
            @submited="submitData"
            @branchesByBusinessUnit="branchesByBusinessUnit"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import HolidayForm from '@/components/forms/register/HolidayForm.vue';
import HolidayDetails from '@/components/details/HolidayDetails.vue';

export default {
  name: 'HolidayDialog',
  components: {
    HolidayForm,
    HolidayDetails,
  },
  model: {
    prop: 'dialog',
    event: 'change',
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    holiday: {
      type: Object,
      default: () => {},
    },
    deleteHoliday: {
      type: Boolean,
      default: false,
    },
    branches: {
      type: Array,
      default: () => [],
    },
    businessUnits: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialogOpen: false,
    };
  },
  computed: {
    dialogInfos() {
      if (this.deleteHoliday) {
        return {
          title: this.$t('components.lists.holiday.delete.title'),
          subtitle: this.$t('components.lists.holiday.delete.subtitle'),
        };
      }

      if (this.edit) {
        return {
          title: this.$t('components.lists.holiday.edit.title'),
          subtitle: this.$t('components.lists.holiday.edit.subtitle'),
        };
      }

      return {
        title: this.$t('components.lists.holiday.register.title'),
        subtitle: this.$t('components.lists.holiday.register.subtitle'),
      };
    },
  },
  watch: {
    dialog(value) {
      this.dialogOpen = value;
    },
    dialogOpen(value) {
      if (!value) {
        this.$emit('change', this.dialogOpen);
      }
    },
  },
  methods: {
    submitData(payload) {
      if (this.edit) {
        this.updateHoliday(payload);
        return;
      }
      this.createHoliday(payload);
    },
    createHoliday(payload) {
      this.$emit('createHoliday', payload);
    },
    updateHoliday(payload) {
      this.$emit('updateHoliday', payload);
    },
    closeModal() {
      this.$emit('change', this.dialogOpen);
    },
    doDeleteHoliday(managerPassword, holidayId) {
      this.$emit('deleteHoliday', { holidayId, managerPassword });
    },
    branchesByBusinessUnit(params) {
      this.$emit('fetchBranchesByBusinessUnit', params);
    },
  },
};
</script>

<style lang="scss" src="@/assets/scss/components/dialogs/drivers.scss" scoped />
