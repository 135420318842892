<template>
  <div class="holiday-tab">
    <holiday-header
      @openModal="openCreateDialog"
    />

    <holiday-list
      :holidays="getHolidays"
      @destroyHoliday="destroyHoliday"
      @editHoliday="openEditDialog"
    />

    <holiday-dialog
      v-model="dialog"
      :edit="edit"
      :holiday="holiday"
      :delete-holiday="shouldDelete"
      :business-units="getBusinessUnits"
      :branches="getBranches"
      @createHoliday="newHoliday"
      @updateHoliday="updateHoliday"
      @deleteHoliday="doDestroyHoliday"
      @fetchBranchesByBusinessUnit="fetchBranches"
    />
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import HolidayDialog from '@/components/dialogs/HolidayDialog.vue';
import HolidayList from '@/components/lists/HolidayList.vue';
import HolidayHeader from '@/components/headers/HolidayHeader.vue';

export default {
  name: 'HolidayTab',
  components: {
    HolidayHeader,
    HolidayList,
    HolidayDialog,
  },
  data() {
    return {
      dialog: false,
      edit: false,
      resetForm: false,
      shouldDelete: false,
      holiday: {},
    };
  },
  computed: {
    ...mapGetters([
      'getHolidays',
      'getHolidayRegisterSuccess',
      'getBusinessUnits',
      'getBranches',
    ]),
  },
  watch: {
    getHolidayRegisterSuccess(value) {
      if (value) {
        this.dialog = false;
        this.fetchHolidays();
      }
    },
  },
  mounted() {
    this.fetchHolidays();
    this.fetchBusinessUnits({ by_active: true });
    this.fetchBranches({ by_active: true });
  },
  methods: {
    ...mapActions([
      'fetchHolidays',
      'createHoliday',
      'updateHoliday',
      'deleteHoliday',
      'fetchBusinessUnits',
      'fetchBranches',
    ]),
    newHoliday(payload) {
      this.createHoliday(payload);
    },
    openCreateDialog() {
      this.fetchBusinessUnits({ by_active: true });
      this.fetchBranches({ by_active: true });
      this.openModal(false, {}, false);
    },
    openEditDialog(payload) {
      this.fetchBusinessUnits({ by_active: true });
      this.fetchBranches({ by_active: true });
      this.openModal(true, payload, false);
    },
    destroyHoliday(holiday) {
      this.holiday = holiday;
      this.openModal(true, holiday, true);
    },
    doDestroyHoliday(params) {
      this.deleteHoliday({
        id: params.holidayId,
        manager_password: params.managerPassword,
      });
    },
    openModal(isEdit, holiday, isDelete) {
      this.edit = isEdit;
      this.shouldDelete = isDelete;

      if (!isDelete) {
        this.setHoliday(isEdit, holiday);
      }

      this.dialog = true;
    },
    setHoliday(isEdit, holiday) {
      if (isEdit) {
        this.holiday = holiday;
      } else {
        this.holiday = {
          name: '',
          date_init: '',
          date_end: '',
        };
      }
    },
  },
};
</script>
