<template>
  <div class="fill-height">
    <v-simple-table
      v-if="branchesPresent"
      class="branches-list"
    >
      <template v-slot:default>
        <thead>
          <tr>
            <th
              class="text-center"
              width="100px"
            >
              {{ $t('components.lists.branches.id') }}
            </th>

            <th class="text-center">
              {{ $t('components.lists.branches.name') }}
            </th>

            <th />
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="branch in branches"
            :key="branch.id"
            class="list-row"
          >
            <td class="text-center">
              {{ branch.id }}
            </td>

            <td class="text-center">
              {{ branch.name }}
            </td>

            <td
              class="text-center buttons"
              width="100px"
            >
              <div v-if="active">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="icon mr-3"
                      color="accent"
                      v-bind="attrs"
                      v-on="on"
                      @click="toggleActivationBranch(branch)"
                    >
                      mdi-minus-circle
                    </v-icon>
                  </template>
                  <span>{{ $t('components.lists.branches.tooltips.deactivate') }}</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="icon ml-2"
                      color="accent"
                      v-bind="attrs"
                      v-on="on"
                      @click="editBranch(branch)"
                    >
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>{{ $t('components.lists.branches.tooltips.edit') }}</span>
                </v-tooltip>
              </div>
              <div v-if="!active">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="icon mr-3"
                      color="accent"
                      v-bind="attrs"
                      v-on="on"
                      @click="toggleActivationBranch(branch)"
                    >
                      mdi-plus-circle
                    </v-icon>
                  </template>
                  <span>{{ $t('components.lists.branches.tooltips.activate') }}</span>
                </v-tooltip>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <placeholder
      v-else
      :message="$t('components.lists.branches.empty')"
    />
  </div>
</template>

<script>
import Placeholder from '@/components/lists/Placeholder.vue';

export default {
  name: 'BranchesList',
  components: {
    Placeholder,
  },
  props: {
    branches: {
      type: Array,
      default: () => [],
    },
    active: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    branchesPresent() {
      return this.branches.length !== 0;
    },
  },
  methods: {
    toggleActivationBranch(branch) {
      this.$emit('toggleActivationBranch', branch);
    },
    editBranch(branch) {
      this.$emit('editBranch', branch);
    },
  },
};
</script>
