<template>
  <div>
    <branches-header
      @fetchBranches="fetchBranchList"
      @openModal="openModal(false, {}, false)"
    />

    <branches-list
      :branches="getBranches"
      :active="active"
      @toggleActivationBranch="toggleActivationBranchSetup"
      @editBranch="editBranchSetup"
    />

    <branches-dialog
      v-model="dialog"
      :edit="edit"
      :branch="branch"
      :units="getBusinessUnits"
      :reset-form="resetForm"
      :deactivate="shouldDeactivate"
      :states="getStates"
      :timezones="getTimezones"
      @createBranch="newBranch"
      @updateBranch="editBranch"
      @toggleActivationBranch="doToggleBranch"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BranchesDialog from '@/components/dialogs/BranchesDialog.vue';
import BranchesList from '@/components/lists/BranchesList.vue';
import BranchesHeader from '@/components/headers/BranchesHeader.vue';

export default {
  name: 'BranchesTab',
  components: {
    BranchesList,
    BranchesDialog,
    BranchesHeader,
  },
  data() {
    return {
      edit: false,
      shouldDeactivate: false,
      active: true,
      dialog: false,
      resetForm: false,
      branch: {
        id: '',
        name: '',
        code: '',
        logo: '',
        cnpj: '',
        phone: '',
        corporateName: '',
        stateRegistration: '',
        state_id: '',
        businessUnit: {
          id: '',
          name: '',
        },
      },
    };
  },
  computed: {
    ...mapGetters([
      'getBranches',
      'getBusinessUnits',
      'getBranchRegisterSuccess',
      'getTimezones',
      'getStates',
    ]),
  },
  watch: {
    getBranchRegisterSuccess(value) {
      if (value) {
        this.closeModal();
        this.fetchBranches({ by_active: this.active });
        this.resetForm = true;
      }
    },
  },
  mounted() {
    this.fetchBranches({ by_active: this.active });
    this.fetchBusinessUnits({ by_active: true });
    this.fetchTimezones();
    this.fetchStates();
  },
  methods: {
    ...mapActions([
      'fetchBranches',
      'fetchStates',
      'fetchBusinessUnits',
      'toggleActivationBranch',
      'updateBranch',
      'createBranch',
      'fetchTimezones',
    ]),
    doToggleBranch(managerPassword, branchId) {
      this.toggleActivationBranch({ manager_password: managerPassword, id: branchId });
    },
    fetchBranchList(active) {
      this.active = active;
      this.fetchBranches({ by_active: active });
    },
    openModal(isEdit, branch, isDeactivate) {
      this.edit = isEdit;
      this.shouldDeactivate = isDeactivate;

      if (!isDeactivate) {
        this.setBranch(isEdit, branch);
      }

      this.dialog = true;
    },
    closeModal() {
      this.dialog = false;
    },
    newBranch(payload) {
      this.createBranch(payload);
    },
    editBranch(payload) {
      this.updateBranch(payload);
    },
    editBranchSetup(branch) {
      this.openModal(true, branch, false);
    },
    toggleActivationBranchSetup(branch) {
      this.branch = branch;
      this.openModal(true, branch, true);
    },
    setBranch(isEdit, branch) {
      if (isEdit) {
        this.branch = branch;
      } else {
        this.branch = {
          id: '',
          name: '',
          code: '',
          cnpj: '',
          logo: '',
          phone: '',
          corporateName: '',
          stateRegistration: '',
          businessUnit: {
            id: '',
            name: '',
          },
        };
      }
    },
  },
};
</script>
