var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-height"},[(_vm.branchesPresent)?_c('v-simple-table',{staticClass:"branches-list",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-center",attrs:{"width":"100px"}},[_vm._v(" "+_vm._s(_vm.$t('components.lists.branches.id'))+" ")]),_c('th',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t('components.lists.branches.name'))+" ")]),_c('th')])]),_c('tbody',_vm._l((_vm.branches),function(branch){return _c('tr',{key:branch.id,staticClass:"list-row"},[_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(branch.id)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(branch.name)+" ")]),_c('td',{staticClass:"text-center buttons",attrs:{"width":"100px"}},[(_vm.active)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"icon mr-3",attrs:{"color":"accent"},on:{"click":function($event){return _vm.toggleActivationBranch(branch)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-minus-circle ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('components.lists.branches.tooltips.deactivate')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"icon ml-2",attrs:{"color":"accent"},on:{"click":function($event){return _vm.editBranch(branch)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('components.lists.branches.tooltips.edit')))])])],1):_vm._e(),(!_vm.active)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"icon mr-3",attrs:{"color":"accent"},on:{"click":function($event){return _vm.toggleActivationBranch(branch)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-plus-circle ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('components.lists.branches.tooltips.activate')))])])],1):_vm._e()])])}),0)]},proxy:true}],null,false,2267879297)}):_c('placeholder',{attrs:{"message":_vm.$t('components.lists.branches.empty')}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }