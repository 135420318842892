<template>
  <v-container class="pb-0">
    <v-row>
      <v-col
        cols="12"
        sm="6"
        class="gutter-right"
      >
        <div class="driver-details">
          <p class="header">
            {{ $t('components.lists.branches.toggle_activation.details.name') }}
          </p>
          <p class="value">
            {{ branch.name }}
          </p>
        </div>
        <div class="driver-details">
          <p class="header">
            {{ $t('components.lists.branches.toggle_activation.details.corporate_name') }}
          </p>
          <p class="value">
            {{ branch.corporate_name }}
          </p>
        </div>
        <div class="driver-details">
          <p class="header">
            {{ $t('components.lists.branches.toggle_activation.details.cnpj') }}
          </p>
          <p class="value">
            {{ branch.cnpj }}
          </p>
        </div>
        <div class="driver-details">
          <p class="header">
            {{ $t('components.lists.branches.toggle_activation.details.state_registration') }}
          </p>
          <p class="value">
            {{ branch.state_registration }}
          </p>
        </div>
        <div class="driver-details">
          <ValidationObserver
            ref="form"
            v-slot="{valid}"
          >
            <v-form
              @keyup.native.enter="submitForm"
            >
              <ValidationProvider
                v-slot="{errors}"
                name="senha"
                rules="required|min:6"
              >
                <v-text-field
                  v-model="managerPassword"
                  outlined
                  dense
                  :error-messages="errors"
                  :label="$t('components.lists.branches.toggle_activation.details.manager_password')"
                  type="password"
                  color="accent"
                  height="42"
                  class="dialog-input"
                />
              </ValidationProvider>
              <v-btn
                class="button"
                color="accent"
                :disabled="!valid"
                @click="submitForm"
              >
                {{ $t(`components.lists.branches.toggle_activation.button.active.${branch.active}`) }}
              </v-btn>
            </v-form>
          </ValidationObserver>
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        class="gutter-right"
      >
        <div class="driver-details">
          <p class="header">
            {{ $t('components.lists.branches.toggle_activation.details.business_unit.name') }}
          </p>
          <p class="value">
            {{ branch.business_unit_name }}
          </p>
        </div>
        <div class="driver-details">
          <p class="header">
            {{ $t('components.lists.branches.toggle_activation.details.time_zone') }}
          </p>
          <p class="value">
            {{ branch.timezone_name }}
          </p>
        </div>
        <div class="driver-details">
          <p class="header">
            {{ $t('components.lists.branches.toggle_activation.details.contact') }}
          </p>
          <p class="value">
            {{ branch.email }}
          </p>
          <p class="value">
            {{ branch.phone }}
          </p>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'BranchDetails',
  props: {
    branch: {
      type: Object,
      default: () => {},
    },
    passwordClean: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      managerPassword: '',
    };
  },
  watch: {
    passwordClean(value) {
      if (!value) {
        this.managerPassword = '';
        this.$nextTick(() => {
          this.$refs.form.reset();
        });
      }
    },
  },
  methods: {
    submitForm() {
      this.$emit('toggleActivationBranch', this.managerPassword, this.branch.id);
    },
  },
};
</script>

<style lang="scss" src="@/assets/scss/components/dialogs/drivers.scss" scoped />
