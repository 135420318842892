<template>
  <v-container class="header">
    <div class="text-right">
      <v-btn
        color="accent"
        class="button pa-5 ml-3"
        @click="openDialog"
      >
        {{ $t('components.header.holidays.create') }}
      </v-btn>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'HolidayHeader',
  methods: {
    toggleActiveList() {
      this.active = !this.active;
      this.fetchProfiles();
    },
    openDialog() {
      this.$emit('openModal');
    },
  },
};
</script>

<style lang="scss" src="@/assets/scss/components/headers/drivers.scss" scoped />
