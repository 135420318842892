<template>
  <div>
    <profile-header
      @fetchDriverProfiles="fetchProfiles"
      @openModal="openModal(false, profile, false)"
    />

    <profile-list
      :profiles="getDriversProfiles"
      :active="active"
      @toggleActivationProfile="toggleActivationProfileSetup"
      @editProfile="editProfileSetup"
    />

    <profile-dialog
      v-model="dialog"
      :edit="edit"
      :profile="profile"
      :reset-form="resetForm"
      :deactivate="shouldDeactivate"
      :activate="shouldActivate"
      @createProfile="newProfile"
      @updateProfile="editProfile"
      @toggleActivationProfile="doToggleProfile"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ProfileList from '@/components/lists/ProfileList.vue';
import ProfileDialog from '@/components/dialogs/ProfileDialog.vue';
import ProfileHeader from '@/components/headers/ProfileHeader.vue';

export default {
  name: 'ProfileTab',
  components: {
    ProfileList,
    ProfileDialog,
    ProfileHeader,
  },
  data() {
    return {
      edit: false,
      shouldDeactivate: false,
      shouldActivate: false,
      active: true,
      dialog: false,
      resetForm: false,
      profile: {
        id: '',
        name: '',
      },
    };
  },
  computed: {
    ...mapGetters([
      'getDriversProfiles',
      'getProfileRegisterSuccess',
      'getProfileActivateSuccess',
      'getProfileDeactivateSuccess',
    ]),
  },
  watch: {
    getProfileRegisterSuccess(value) {
      if (value) {
        this.closeModal();
        this.fetchDriverProfiles({ by_active: this.active });
        this.resetForm = true;
      }
    },
  },
  mounted() {
    this.fetchDriverProfiles({ by_active: this.active });
  },
  methods: {
    ...mapActions([
      'createDriverProfile',
      'updateDriverProfile',
      'fetchDriverProfiles',
      'toggleActivationProfile',
    ]),
    doToggleProfile(params) {
      this.toggleActivationProfile({
        id: params.profileId,
        manager_password: params.managerPassword,
      });
    },
    fetchProfiles(active) {
      this.active = active;
      this.fetchDriverProfiles({ by_active: active });
    },
    openModal(isEdit, profile, isDeactivate) {
      this.edit = isEdit;
      this.shouldDeactivate = isDeactivate;

      if (!isDeactivate) {
        this.setProfile(isEdit, profile);
      }

      this.dialog = true;
    },
    closeModal() {
      this.dialog = false;
    },
    newProfile(payload) {
      this.createDriverProfile(payload);
    },
    editProfile(payload) {
      this.updateDriverProfile(payload);
    },
    editProfileSetup(profile) {
      this.openModal(true, profile, false);
    },
    toggleActivationProfileSetup(profile) {
      this.profile = profile;
      this.openModal(true, profile, true);
    },
    setProfile(isEdit, profile) {
      if (isEdit) {
        this.profile = profile;
      } else {
        this.profile = {
          name: '',
        };
      }
    },
  },
};
</script>
