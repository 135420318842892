<template>
  <v-row
    no-gutters
    justify="center"
  >
    <v-dialog
      v-model="dialogOpen"
      max-width="730px"
      :change="closeModal()"
    >
      <v-card>
        <v-card-title class="dialog-title">
          <span>{{ dialogInfos.title }}</span>
        </v-card-title>
        <v-card-text
          class="dialog-subtitle"
        >
          {{ dialogInfos.subtitle }}
        </v-card-text>
        <v-card-text
          v-if="deactivate || activate"
          class="dialog-details"
        >
          <profile-details
            :profile="profile"
            :password-clean="dialogOpen"
            @toggleActivationProfile="toggleActivationProfile"
          />
        </v-card-text>

        <v-card-text
          v-else
          class="dialog-form"
        >
          <profile-form
            :edit="edit"
            :profile="profile"
            :reset-form="shouldCleanForm"
            @createProfile="createProfile"
            @updateProfile="updateProfile"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import ProfileForm from '@/components/forms/register/ProfileForm.vue';
import ProfileDetails from '@/components/details/ProfileDetails.vue';

export default {
  name: 'ProfileDialog',
  components: {
    ProfileForm,
    ProfileDetails,
  },
  model: {
    prop: 'dialog',
    event: 'change',
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    deactivate: {
      type: Boolean,
      default: false,
    },
    activate: {
      type: Boolean,
      default: false,
    },
    profile: {
      type: Object,
      default: () => {},
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    resetForm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogOpen: false,
    };
  },
  computed: {
    shouldCleanForm() {
      return this.resetForm;
    },

    dialogInfos() {
      if (this.deactivate) {
        return {
          title: this.$t(`components.lists.profiles.toggle_activation.title.active.${this.profile.active}`),
          subtitle: this.$t(`components.lists.profiles.toggle_activation.subtitle.active.${this.profile.active}`),
        };
      }

      if (this.edit) {
        return {
          title: this.$t('components.lists.profiles.edit.title'),
          subtitle: this.$t('components.lists.profiles.edit.subtitle'),
        };
      }

      return {
        title: this.$t('components.lists.profiles.register.title'),
        subtitle: this.$t('components.lists.profiles.register.subtitle'),
      };
    },
  },
  watch: {
    dialog() {
      this.dialogOpen = this.dialog;
    },
  },
  methods: {
    createProfile(payload) {
      this.$emit('createProfile', payload);
    },

    toggleActivationProfile(managerPassword, profileId) {
      this.$emit('toggleActivationProfile', { profileId, managerPassword });
    },

    updateProfile(payload) {
      this.$emit('updateProfile', payload);
    },

    closeModal() {
      this.$emit('change', this.dialogOpen);
    },
  },
};
</script>

<style lang="scss" src="@/assets/scss/components/dialogs/drivers.scss" scoped />
