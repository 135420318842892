<template>
  <v-row
    no-gutters
    justify="center"
  >
    <v-dialog
      v-model="dialogOpen"
      max-width="730px"
      :change="closeModal()"
    >
      <v-card>
        <v-card-title class="dialog-title">
          <span>{{ dialogInfos.title }}</span>
        </v-card-title>
        <v-card-text
          class="dialog-subtitle"
        >
          {{ dialogInfos.subtitle }}
        </v-card-text>
        <v-card-text
          v-if="deactivate"
          class="dialog-details"
        >
          <branch-details
            :branch="branch"
            :password-clean="dialogOpen"
            @toggleActivationBranch="toggleActivationBranch"
          />
        </v-card-text>
        <v-card-text
          v-else
          class="dialog-form"
        >
          <branch-form
            :edit="edit"
            :branch="branch"
            :units="units"
            :states="states"
            :timezones="timezones"
            @submitBranch="submitBranch"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import BranchForm from '@/components/forms/register/BranchForm.vue';
import BranchDetails from '@/components/details/BranchDetails.vue';

export default {
  name: 'BranchesDialog',
  components: {
    BranchForm,
    BranchDetails,
  },
  model: {
    prop: 'dialog',
    event: 'change',
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    deactivate: {
      type: Boolean,
      default: false,
    },
    branch: {
      type: Object,
      default: () => {},
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    resetForm: {
      type: Boolean,
      default: false,
    },
    units: {
      type: Array,
      default: () => [],
    },
    states: {
      type: Array,
      default: () => [],
    },
    timezones: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialogOpen: false,
    };
  },
  computed: {
    shouldCleanForm() {
      return this.resetForm;
    },

    dialogInfos() {
      if (this.deactivate) {
        return {
          title: this.$t(`components.lists.branches.toggle_activation.title.active.${this.branch.active}`),
          subtitle: this.$t(`components.lists.branches.toggle_activation.subtitle.active.${this.branch.active}`),
        };
      }

      if (this.edit) {
        return {
          title: this.$t('components.lists.branches.edit.title'),
          subtitle: this.$t('components.lists.branches.edit.subtitle'),
        };
      }

      return {
        title: this.$t('components.lists.branches.register.title'),
        subtitle: this.$t('components.lists.branches.register.subtitle'),
      };
    },
  },
  watch: {
    dialog() {
      this.dialogOpen = this.dialog;
    },
  },
  methods: {
    submitBranch(payload) {
      if (this.edit) {
        return this.$emit('updateBranch', payload);
      }

      return this.$emit('createBranch', payload);
    },

    toggleActivationBranch(managerPassword, branchId) {
      this.$emit('toggleActivationBranch', managerPassword, branchId);
    },

    closeModal() {
      this.$emit('change', this.dialogOpen);
    },
  },
};
</script>

<style lang="scss" src="@/assets/scss/components/dialogs/drivers.scss" scoped />
