<template>
  <setting-tabs />
</template>

<script>
import SettingTabs from '@/components/tabs/SettingTabs.vue';
import { mapActions } from 'vuex';

export default {
  name: 'Setting',
  components: {
    SettingTabs,
  },
  mounted() {
    this.initCurrentBreadcrumbs([
      {
        text: this.$i18n.t('breadcrumbs.settings'),
        disabled: true,
      },
    ]);
  },
  methods: {
    ...mapActions([
      'initCurrentBreadcrumbs',
    ]),
  },
};
</script>

<style lang="scss">
.cancel-button {
  border: 1px solid $color_primary;
  border-radius: 4px;
  opacity: 1;
  text-align: center;
}

.spacing {
  margin: 8px;
}

.v-btn.v-size--default {
  color: $color_primary;
}
</style>
