<template>
  <v-container>
    <v-tabs
      class="setting-tabs"
      vertical
      align-left
      hide-slider
      background-color="setings_tabs"
    >
      <v-tab>
        <v-icon
          left
        >
          mdi-domain
        </v-icon>

        <p class="mb-0 mt-1">
          {{ $t('components.lists.units.tab_title') }}
        </p>
      </v-tab>

      <v-tab>
        <v-icon
          left
        >
          mdi-storefront-outline
        </v-icon>

        <p class="mb-0 mt-1">
          {{ $t('components.lists.branches.tab_title') }}
        </p>
      </v-tab>

      <v-tab>
        <v-icon
          left
        >
          mdi-truck-outline
        </v-icon>

        <p class="mb-0 mt-1">
          {{ $t('components.lists.profiles.tab_title') }}
        </p>
      </v-tab>

      <v-tab>
        <v-icon
          left
        >
          mdi-calendar-today
        </v-icon>

        <p class="mb-0 mt-1">
          {{ $t('components.lists.holiday.tab_title') }}
        </p>
      </v-tab>

      <v-tab>
        <v-icon
          left
        >
          mdi-message-draw
        </v-icon>

        <p class="mb-0 mt-1">
          {{ $t('components.lists.adjustments.tab_title') }}
        </p>
      </v-tab>

      <v-tab-item>
        <business-unit-tab />
      </v-tab-item>

      <v-tab-item>
        <branches-tab />
      </v-tab-item>

      <v-tab-item>
        <profile-tab />
      </v-tab-item>

      <v-tab-item>
        <holiday-tab />
      </v-tab-item>

      <v-tab-item>
        <adjustment-tab />
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import ProfileTab from '@/components/tabs/settings/ProfileTab.vue';
import BusinessUnitTab from '@/components/tabs/settings/BusinessUnitTab.vue';
import BranchesTab from '@/components/tabs/settings/BranchesTab.vue';
import HolidayTab from '@/components/tabs/settings/HolidayTab.vue';
import AdjustmentTab from '@/components/tabs/settings/AdjustmentTab.vue';

export default {
  name: 'SettingTabs',
  components: {
    ProfileTab,
    BusinessUnitTab,
    BranchesTab,
    HolidayTab,
    AdjustmentTab,
  },
};
</script>

<style lang="scss">
.theme--light.v-tabs > .v-tabs-bar {
  margin-top: 132px;
  height: 100%;
}

.setting-tabs {
  .v-tab {
    justify-content: left;
    text-transform: none;
    font-size: 14px;
    font-family: 'Acumin-Pro-Regular';
  }

  .theme--light.v-tabs-items {
    background-color: $color_background;
  }

  .v-tabs--vertical > .v-tabs-bar .v-tab {
    height: 56px;
    width: 262px;
  }

  .v-tabs--vertical > .v-tabs-bar .v-tabs-slider {
    color:$color_primary;
  }

  .v-tab--active {
    background-color: $color_secondary_dark;
    border-radius: 4px;
  }
}
</style>
