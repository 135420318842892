<template>
  <v-row
    no-gutters
    justify="center"
  >
    <v-dialog
      v-model="dialogOpen"
      max-width="730px"
      :change="closeModal()"
    >
      <v-card>
        <v-card-title class="dialog-title">
          <span>{{ dialogInfos.title }}</span>
        </v-card-title>
        <v-card-text
          class="dialog-subtitle"
        >
          {{ dialogInfos.subtitle }}
        </v-card-text>
        <v-card-text
          v-if="deactivate"
          class="dialog-details"
        >
          <business-unit-details
            :unit="unit"
            :password-clean="dialogOpen"
            @toggleActivationUnit="toggleActivationUnit"
          />
        </v-card-text>
        <v-card-text
          v-else
          class="dialog-form"
        >
          <business-unit-form
            :edit="edit"
            :unit="unit"
            :reset-form="shouldCleanForm"
            @createUnit="createUnit"
            @updateUnit="updateUnit"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import BusinessUnitForm from '@/components/forms/register/BusinessUnitForm.vue';
import BusinessUnitDetails from '@/components/details/BusinessUnitDetails.vue';

export default {
  name: 'BusinessUnitDialog',
  components: {
    BusinessUnitForm,
    BusinessUnitDetails,
  },
  model: {
    prop: 'dialog',
    event: 'change',
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    deactivate: {
      type: Boolean,
      default: false,
    },
    unit: {
      type: Object,
      default: () => {},
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    resetForm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogOpen: false,
    };
  },
  computed: {
    shouldCleanForm() {
      return this.resetForm;
    },

    dialogInfos() {
      if (this.deactivate) {
        return {
          title: this.$t(`components.lists.units.toggle_activation.title.active.${this.unit.active}`),
          subtitle: this.$t(`components.lists.units.toggle_activation.subtitle.active.${this.unit.active}`),
        };
      }

      if (this.edit) {
        return {
          title: this.$t('components.lists.units.edit.title'),
          subtitle: this.$t('components.lists.units.edit.subtitle'),
        };
      }

      return {
        title: this.$t('components.lists.units.register.title'),
        subtitle: this.$t('components.lists.units.register.subtitle'),
      };
    },
  },
  watch: {
    dialog() {
      this.dialogOpen = this.dialog;
    },
  },
  methods: {
    createUnit(payload) {
      this.$emit('createUnit', payload);
    },

    updateUnit(payload) {
      this.$emit('updateUnit', payload);
    },

    toggleActivationUnit(managerPassword, unitId) {
      this.$emit('toggleActivationUnit', { unitId, managerPassword });
    },

    closeModal() {
      this.$emit('change', this.dialogOpen);
    },
  },
};
</script>

<style lang="scss" src="@/assets/scss/components/dialogs/drivers.scss" scoped />
