<template>
  <v-container class="pb-0">
    <v-row>
      <v-col
        cols="6"
        sm="6"
        class="gutter-right"
      >
        <v-text-field
          v-model="name"
          outlined
          dense
          required
          :label="$t('components.lists.adjustments.register.form.name')"
          color="accent"
          height="42"
          class="dialog-input pb-4"
        />

        <v-btn
          class="button"
          color="accent"
          @click="submitForm"
        >
          {{ buttonText }}
        </v-btn>
      </v-col>

      <v-col
        cols="6"
        sm="6"
        class="gutter-right"
      >
        <v-select
          v-model="kind"
          outlined
          dense
          required
          :items="kinds"
          item-text="name"
          item-value="key"
          :label="$t('components.lists.adjustments.register.form.kind')"
          color="accent"
          height="42"
          class="dialog-input pb-4"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'AdjustmentForm',
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    adjustment: {
      type: Object,
      default: () => {},
    },
    timezones: {
      type: Array,
      default: () => [],
    },
    kinds: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      name: '',
      kind: '',
    };
  },
  computed: {
    buttonText() {
      if (this.edit) {
        return this.$t('components.lists.adjustments.edit.title');
      }

      return this.$t('components.lists.adjustments.register.title');
    },
  },
  watch: {
    adjustment(value) {
      if (value) {
        this.name = value.name;
        this.kind = value.kind;
      }
    },
  },
  created() {
    if (this.adjustment) {
      this.name = this.adjustment.name;
      this.kind = this.adjustment.kind;
    }
  },
  methods: {
    submitForm() {
      if (this.edit) {
        return this.updateAdjustment();
      }

      return this.createAdjustment();
    },

    createAdjustment() {
      const payload = this.assertPayload();

      this.$emit('createAdjustment', payload);
    },

    updateAdjustment() {
      const payload = this.assertPayload();

      this.$emit('updateAdjustment', payload);
    },

    assertPayload() {
      const payload = {
        id: this.adjustment.id,
        kind: this.kind,
        name: this.name,
      };

      return payload;
    },
  },
};
</script>

<style lang="scss" src="@/assets/scss/components/dialogs/drivers.scss" scoped />
