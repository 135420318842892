<template>
  <div>
    <adjustments-header
      @fetchAdjustments="fetchAdjustmentList"
      @openModal="openModal(false, adjustment, false)"
    />

    <adjustments-list
      :adjustments="getAdjustmentReasons"
      :active="active"
      @toggleActivationAdjustment="toggleActivationAdjustmentSetup"
      @editAdjustment="editAdjustmentSetup"
    />

    <adjustments-dialog
      v-model="dialog"
      :edit="edit"
      :adjustment="adjustment"
      :reset-form="resetForm"
      :deactivate="shouldDeactivate"
      :kinds="adjustmentKinds"
      @createAdjustment="newAdjustment"
      @updateAdjustment="editAdjustment"
      @toggleActivationAdjustment="doToggleAdjustment"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AdjustmentsDialog from '@/components/dialogs/AdjustmentsDialog.vue';
import AdjustmentsList from '@/components/lists/AdjustmentsList.vue';
import AdjustmentsHeader from '@/components/headers/AdjustmentsHeader.vue';

export default {
  name: 'AdjustmentTab',
  components: {
    AdjustmentsList,
    AdjustmentsDialog,
    AdjustmentsHeader,
  },
  data() {
    return {
      edit: false,
      shouldDeactivate: false,
      active: true,
      dialog: false,
      resetForm: false,
      adjustmentKinds: [
        {
          key: 'allowance',
          name: this.$t('components.lists.adjustments.adjustment_kinds.allowance'),
        },
        {
          key: 'dsr',
          name: this.$t('components.lists.adjustments.adjustment_kinds.dsr'),
        },
        {
          key: 'cct',
          name: this.$t('components.lists.adjustments.adjustment_kinds.cct'),
        },
        {
          key: 'absence_with_discount',
          name: this.$t('components.lists.adjustments.adjustment_kinds.absence_with_discount'),
        },
        {
          key: 'adjustment',
          name: this.$t('components.lists.adjustments.adjustment_kinds.adjustment'),
        },
      ],
      adjustment: {
        id: '',
        name: '',
        kind: '',
      },
    };
  },
  computed: {
    ...mapGetters([
      'getAdjustmentReasons',
      'getAdjustmentRegisterSuccess',
    ]),
  },
  watch: {
    getAdjustmentRegisterSuccess(value) {
      if (value) {
        this.closeModal();
        this.fetchAdjustmentReasons({ by_active: this.active });
        this.resetForm = true;
      }
    },
  },
  mounted() {
    this.fetchAdjustmentReasons({ by_active: this.active });
  },
  methods: {
    ...mapActions([
      'fetchAdjustmentReasons',
      'toggleActivationAdjustmentReason',
      'updateAdjustmentReason',
      'createAdjustmentReason',
    ]),
    doToggleAdjustment(params) {
      this.toggleActivationAdjustmentReason({
        id: params.adjustmentId,
        manager_password: params.managerPassword,
      });
    },
    fetchAdjustmentList(active) {
      this.active = active;
      this.fetchAdjustmentReasons({ by_active: active });
    },
    openModal(isEdit, adjustment, isDeactivate) {
      this.edit = isEdit;
      this.shouldDeactivate = isDeactivate;

      if (!isDeactivate) {
        this.setAdjustment(isEdit, adjustment);
      }

      this.dialog = true;
    },
    closeModal() {
      this.dialog = false;
    },
    newAdjustment(payload) {
      this.createAdjustmentReason(payload);
    },
    editAdjustment(payload) {
      this.updateAdjustmentReason(payload);
    },
    editAdjustmentSetup(adjustment) {
      this.openModal(true, adjustment, false);
    },
    toggleActivationAdjustmentSetup(adjustment) {
      this.adjustment = adjustment;
      this.openModal(true, adjustment, true);
    },
    setAdjustment(isEdit, adjustment) {
      if (isEdit) {
        this.adjustment = adjustment;
      } else {
        this.adjustment = {
          id: '',
          name: '',
          kind: '',
        };
      }
    },
  },
};
</script>
