<template>
  <v-container class="pb-0">
    <v-row>
      <v-col
        cols="6"
        sm="6"
        class="gutter-right"
      >
        <v-text-field
          v-model="name"
          outlined
          dense
          required
          :label="$t('components.lists.branches.register.form.name')"
          color="accent"
          height="42"
          class="dialog-input pb-4"
        />

        <v-text-field
          v-model="cnpj"
          v-mask="'##.###.###/####-##'"
          outlined
          dense
          required
          :label="$t('components.lists.branches.register.form.cnpj')"
          color="accent"
          height="42"
          class="dialog-input pb-4"
        />

        <v-text-field
          v-model="stateRegistration"
          outlined
          dense
          required
          :label="$t('components.lists.branches.register.form.state_registration')"
          color="accent"
          height="42"
          class="dialog-input pb-4"
        />

        <v-text-field
          v-model="phone"
          v-mask="['(##) ####-####', '(##) #####-####']"
          outlined
          dense
          required
          :label="$t('components.lists.branches.register.form.phone')"
          color="accent"
          height="42"
          class="dialog-input pb-4"
        />

        <div class="d-flex">
          <v-img
            v-if="preview"
            :src="preview"
            max-height="50px"
            width="120px"
            contain
            content-class="pb-0"
          />

          <v-file-input
            v-model="logoImage"
            hide-input
            :prepend-icon="logoButtonText"
            :label="$t('components.lists.branches.register.form.logo')"
            color="accent"
            truncate-length="13"
            class="image-input-button"
            @change="createImagePreview"
          />
        </div>

        <v-btn
          class="button"
          color="accent"
          @click="submitBranch"
        >
          {{ buttonText }}
        </v-btn>
      </v-col>

      <v-col
        cols="6"
        sm="6"
        class="gutter-right"
      >
        <v-select
          v-model="businessUnitId"
          outlined
          dense
          :items="units"
          item-text="name"
          item-value="id"
          :label="$t('components.lists.branches.register.form.units')"
          color="accent"
          height="42"
          class="dialog-input pb-4"
        />

        <v-text-field
          v-model="corporateName"
          outlined
          dense
          required
          :label="$t('components.lists.branches.register.form.corporate_name')"
          color="accent"
          height="42"
          class="dialog-input pb-4"
        />

        <v-select
          v-model="stateId"
          outlined
          dense
          required
          :items="states"
          :label="$t('components.lists.drivers.register.form.state')"
          item-text="name"
          item-value="id"
          color="accent"
          height="42"
          class="dialog-input pb-4"
        />

        <v-select
          v-model="timezoneId"
          outlined
          dense
          :items="timezones"
          item-text="name"
          item-value="id"
          :label="$t('components.lists.branches.register.form.timezones')"
          color="accent"
          height="42"
          class="dialog-input pb-4"
        />

        <v-text-field
          v-model="email"
          outlined
          dense
          required
          :label="$t('components.lists.branches.register.form.email')"
          color="accent"
          height="42"
          class="dialog-input pb-4"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'BranchForm',
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    branch: {
      type: Object,
      default: () => {},
    },
    units: {
      type: Array,
      default: () => [],
    },
    states: {
      type: Array,
      default: () => [],
    },
    timezones: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      preview: null,
      name: '',
      cnpj: '',
      stateRegistration: '',
      phone: '',
      businessUnitId: null,
      stateId: null,
      corporateName: '',
      timezoneId: null,
      email: '',
      logo: {},
      logoImage: [],
      logoImageBase64: '',
    };
  },
  computed: {
    logoButtonText() {
      if (!!this.branch.logo || !!this.preview) {
        return this.$t('components.lists.branches.register.form.logoImage.edit');
      }

      return this.$t('components.lists.branches.register.form.logoImage.new');
    },

    buttonText() {
      if (this.edit) {
        return this.$t('components.lists.branches.edit.title');
      }

      return this.$t('components.lists.branches.register.title');
    },
  },
  watch: {
    branch(value) {
      if (value) {
        this.name = value.name;
        this.cnpj = value.cnpj;
        this.corporateName = value.corporate_name;
        this.phone = value.phone;
        this.businessUnitId = value.business_unit_id;
        this.stateRegistration = value.state_registration;
        this.timezoneId = value.timezone_id;
        this.stateId = value.state_id;
        this.email = value.email;
        this.logo = value.logo;
        this.preview = value.logo ? value.logo.url : null;
      }
    },
    logoImage(value) {
      if (value) {
        this.imagePicked(value);
      }
    },

    logoImageBase64(value) {
      if (value) {
        this.logo = value;
      }
    },
  },
  created() {
    if (this.branch) {
      this.name = this.branch.name;
      this.cnpj = this.branch.cnpj;
      this.corporateName = this.branch.corporate_name;
      this.phone = this.branch.phone;
      this.businessUnitId = this.branch.business_unit_id;
      this.stateRegistration = this.branch.state_registration;
      this.timezoneId = this.branch.timezone_id;
      this.stateId = this.branch.state_id;
      this.email = this.branch.email;
      this.logo = this.branch.logo;
      this.preview = this.branch.logo ? this.branch.logo.url : null;
    }
  },
  methods: {
    submitBranch() {
      const payload = this.assertPayload();

      this.$emit('submitBranch', payload);
    },

    createImagePreview() {
      this.preview = URL.createObjectURL(this.logoImage);
    },

    imagePicked(pickedImage) {
      const fileReader = new FileReader();

      fileReader.addEventListener('load', (e) => {
        this.logoImageBase64 = e.target.result;
      });

      return fileReader.readAsDataURL(pickedImage);
    },
    assertPayload() {
      const payload = {
        id: this.branch.id,
        name: this.name,
        cnpj: this.cnpj,
        state_registration: this.stateRegistration,
        phone: this.phone,
        business_unit_id: this.businessUnitId,
        state_id: this.stateId,
        corporate_name: this.corporateName,
        timezone_id: this.timezoneId,
        email: this.email,
        logo: this.logo,
      };

      return payload;
    },
  },
};
</script>

<style lang="scss" src="@/assets/scss/components/dialogs/drivers.scss" scoped />

<style lang="scss">
  .image-input-button {
    width: 100%;
    height: 50px;
    border: 1px solid $color_primary;
    border-radius: 4px;
    font-size: 8px;
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 40px;

    .v-input__append-outer, .v-input__prepend-outer {
      margin-right: 0;
      width: 100%;
      height: 100%;
      margin-top: 0;
      margin-bottom: 0;

      .v-input__icon {
        height: 100%;

        .v-icon.v-icon.v-icon--link {
          width: 100%;
          height: 100%;
          cursor: pointer;
          outline: none;
          font-size: 10px;
          text-transform: uppercase;
          font-weight: 700;
          font-family: 'Fira-Sans-Bold';
          color: $color_primary;

          &:after {
            background-color: transparent;
          }
        }
      }
    }
  }
</style>
