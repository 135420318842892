<template>
  <div>
    <business-unit-header
      @fetchUnits="fetchUnits"
      @openModal="openModal(false, unit, false)"
    />

    <business-unit-list
      :business-units="getBusinessUnits"
      :active="active"
      @editUnit="editUnitSetup"
      @toggleActivationUnit="toggleActivationUnitSetup"
    />

    <business-unit-dialog
      v-model="dialog"
      :edit="edit"
      :unit="unit"
      :reset-form="resetForm"
      :deactivate="shouldDeactivate"
      @createUnit="newUnit"
      @updateUnit="editUnit"
      @toggleActivationUnit="doToggleUnit"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BusinessUnitDialog from '@/components/dialogs/BusinessUnitDialog.vue';
import BusinessUnitList from '@/components/lists/BusinessUnitList.vue';
import BusinessUnitHeader from '@/components/headers/BusinessUnitHeader.vue';

export default {
  name: 'BusinessUnitTab',
  components: {
    BusinessUnitList,
    BusinessUnitDialog,
    BusinessUnitHeader,
  },
  data() {
    return {
      edit: false,
      shouldDeactivate: false,
      active: true,
      dialog: false,
      resetForm: false,
      unit: {
        id: '',
        name: '',
      },
    };
  },
  computed: {
    ...mapGetters([
      'getBusinessUnits',
      'getUnitRegisterSuccess',
    ]),
  },
  watch: {
    getUnitRegisterSuccess(value) {
      if (value) {
        this.closeModal();
        this.fetchBusinessUnits({ by_active: this.active });
        this.resetForm = true;
      }
    },
  },
  mounted() {
    this.fetchBusinessUnits({ by_active: this.active });
  },
  methods: {
    ...mapActions([
      'fetchBusinessUnits',
      'toggleActivationUnit',
      'updateUnit',
      'createUnit',
    ]),
    doToggleUnit(params) {
      this.toggleActivationUnit({
        id: params.unitId,
        manager_password: params.managerPassword,
      });
    },
    fetchUnits(active) {
      this.active = active;
      this.fetchBusinessUnits({ by_active: active });
    },
    openModal(isEdit, unit, isDeactivate) {
      this.edit = isEdit;
      this.shouldDeactivate = isDeactivate;

      if (!isDeactivate) {
        this.setUnit(isEdit, unit);
      }

      this.dialog = true;
    },
    closeModal() {
      this.dialog = false;
    },
    newUnit(payload) {
      this.createUnit(payload);
    },
    editUnit(payload) {
      this.updateUnit(payload);
    },
    editUnitSetup(unit) {
      this.openModal(true, unit, false);
    },
    toggleActivationUnitSetup(unit) {
      this.unit = unit;
      this.openModal(true, unit, true);
    },
    setUnit(isEdit, unit) {
      if (isEdit) {
        this.unit = unit;
      } else {
        this.unit = {
          name: '',
          code: '',
        };
      }
    },
  },
};
</script>
