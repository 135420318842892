import { render, staticRenderFns } from "./HolidayDialog.vue?vue&type=template&id=18b8994e&scoped=true&"
import script from "./HolidayDialog.vue?vue&type=script&lang=js&"
export * from "./HolidayDialog.vue?vue&type=script&lang=js&"
import style0 from "@/assets/scss/components/dialogs/drivers.scss?vue&type=style&index=0&id=18b8994e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18b8994e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardText,VCardTitle,VDialog,VRow})
