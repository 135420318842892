<template>
  <v-container class="pb-0">
    <v-row>
      <v-col
        cols="12"
        sm="6"
        class="gutter-right"
      >
        <v-text-field
          v-model="name"
          outlined
          dense
          required
          :label="$t('components.lists.units.register.form.name')"
          color="accent"
          height="42"
          class="dialog-input pb-4"
        />

        <v-btn
          class="button"
          color="accent"
          @click="submitForm"
        >
          {{ buttonText }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'BusinessUnitForm',
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    unit: {
      type: Object,
      default: () => {},
    },
    resetForm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      id: this.unit.id,
      name: this.unit.name,
    };
  },
  computed: {
    buttonText() {
      if (this.edit) {
        return this.$t('components.lists.units.edit.title');
      }

      return this.$t('components.lists.units.register.title');
    },
  },
  watch: {
    resetForm(value) {
      if (value) {
        this.clearForm();
      }
    },
    unit(value) {
      if (value) {
        this.id = value.id;
        this.name = value.name;
      }
    },
  },
  methods: {
    submitForm() {
      if (this.edit) {
        return this.updateUnit();
      }

      return this.createUnit();
    },

    createUnit() {
      const payload = this.assertPayload();

      this.$emit('createUnit', payload);
    },

    updateUnit() {
      const payload = this.assertPayload();

      this.$emit('updateUnit', payload);
    },

    assertPayload() {
      const payload = {
        id: this.id,
        name: this.name,
      };

      return payload;
    },

    clearForm() {
      this.id = '';
      this.name = '';
    },
  },
};
</script>

<style lang="scss" src="@/assets/scss/components/dialogs/drivers.scss" scoped />
